@media only screen and (max-width: 600px) {
  .banner {
    height: 10% !important;
  }

  .big-title {
    margin: 1em;
    width: 80% !important;
    font-size: 48px !important;
  }

  .home-desc {
    font-size: 16px !important;
    width: 75% !important;
  }

  .create-text {
    width: 80% !important;
  }

  .footer {
    overflow: clip;
  }

  .content-container {
    display: block !important;
    height: auto !important;
    width: 100% !important;
  }

  .video-container {
    margin-top: 0 !important;
    width: 100% !important;
  }

  .sidebar {
    width: 100% !important;
    margin-top: 3em !important;
  }

  .duration-controls {
    justify-content: space-around !important;
    flex-grow: .8 !important;
  }
  .volume-slider {
    flex-grow: .8 !important;
  }
}

/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/outfit/v5/QGYyz_MVcBeNP4NjuGObqx1XmO1I4QK1O4a0Ew.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


body {
  margin: 0;
  font-family: "Outfit" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.home-desc-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.big-title {
  font-weight: 900;
  font-size: 72px;
  text-align: center;
  width: 100%;
}

.home-desc {
  font-size: 24px;
  text-align: center;
  width: 50%;
}
