$column-width: 480px;
$gutter-width: 20px;

.player-wrapper {
  width: 480px;
  height: 270px;
}

.react-player {
  margin-bottom: 10px;
  background: rgba(0, 0, 0, .1);
}

.faded {
  color: rgba(0, 0, 0, .5);
}

.footer {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 90%;
}

.video-container {
  margin: auto;
  height: 75vh;
  width: 75%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.banner {
  background-color: #B1FBE4;
  height: 10vh;
}

.content-container {
  display: flex;
  height: 100%;
}

.react-player {
  /*background-color: black;*/
  height: 85% !important;
  margin-bottom: 0;
}

.watcher-list {
  margin: auto;
  width: 75%;
}

.watcher-text {
  margin-left: .5em;
  margin-right: .5em;
}

.sidebar {
  margin: auto;
  width: 15%;
  margin-top: 1em;
}

.name-text {
  text-transform:capitalize;
}

.custom-button {
  color: white;
  background-color: rgb(123, 31, 162);
}


.custom-button:hover {
  background-color: black;
}

.url-form {
  display: flex;
}

.copy-button-container {
  display: flex;
  justify-content: center;
}

.logo {
  width: 64px;
}

.logo-container {
  margin: auto;
  width: 50%;
  text-align: center;
  cursor: pointer;
}

.homepage-container {
  height: 80vh;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  gap: 1em;
}

.join-container {
  display: flex;
  margin: auto;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 1em;
}

.create-container {
  display: flex;
  margin: auto;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 1em;
}

.create-text {
  display: flex;
  justify-content: center;
  margin: auto;
  flex-wrap: wrap;
}

.new-room-field {
  width: 100%;
  margin-bottom: 1em !important;
}

.home-button {
  font-size: 18px !important;
}

.name-self {
  cursor: pointer
}

.watermelon-tree-left {
  width: 64px;
  -ms-transform: rotate(-45deg); /* IE 9 */
  -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
  transform: rotate(-45deg);
}


.watermelon-tree-right {
  width: 64px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.watermelon-tree-left:hover {
  transform: rotate(360deg);
  transition: all 0.3s ease-in-out 0s;
}

.watermelon-tree-right:hover {
  transform: rotate(-360deg);
  transition: all 0.3s ease-in-out 0s;
}

.low-banner {
  min-height: 3em;
}

.slider-container {
  display: flex;
  justify-content: center;
  height: 15vh;
  background-color: #282f31;
  flex-wrap: wrap;
}

.video-slider {
  margin-top: 1em;
  width: 95% !important;
}

.controls-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 1em;
  margin-right: 1em;
}

.play-button {
  cursor: pointer;
  color: white;
}

.pause-button {
  cursor: pointer;
  color: white;
}

.time-passed {
  color: white;
  margin-right: 1em;
}

.time-remaining {
  color: white;
  margin-left: 1em;
}

.volume-slider {
  flex-grow: .1;
  display: flex;
}

.volume-icon {
  cursor: pointer;
  color: white;
}

.mute-icon {
  cursor: pointer;
  color: white;
}

.fullscreen-icon {
  cursor: pointer;
  color: white;
}

.volume-container {
  width: 100%;
  transition: left 0.3s;
}

.Mui-disabled {
  display: none !important;
}

.lower-controls-container {
  width: 95%;
  display: flex;
  justify-content: space-between;
}

.duration-controls {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding-right: 7em;
}

.duration-control {
  width: 10%
}